var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.stringsShared)?_c('div',{class:[
    _vm.$options.filters && _vm.$options.filters.modifiers('meter', _vm.meter.modifiers),
    { 'is-submitted': _vm.meter.isSubmitted } ]},[_c('div',{staticClass:"header"},[(
        _vm.i18n.pictos[
          'picto' + _vm.meter.type.charAt(0).toUpperCase() + _vm.meter.type.slice(1)
        ]
      )?_c('img',{staticClass:"header__icon",attrs:{"src":_vm.apiDomain +
        _vm.i18n.pictos[
          'picto' + _vm.meter.type.charAt(0).toUpperCase() + _vm.meter.type.slice(1)
        ].url,"alt":""}}):_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"header__icon",class:[_vm.meter.type],attrs:{"symbol":("icons-" + (_vm.meter.type) + "New"),"size":"0 0 48 48"}}),_c('div',[_c('span',{staticClass:"header__headline headline"},[(_vm.meter.isSubmitted && !_vm.meter.headline)?[_vm._v(" "+_vm._s(_vm.stringsShared.meterC.validated)+" ")]:_vm._e(),(!_vm.meter.isSubmitted && !_vm.meter.headline)?[_vm._v(" "+_vm._s(_vm.stringsShared.meterC.submitYourIndex)+" ")]:_vm._e(),(_vm.meter.headline)?[_vm._v(" "+_vm._s(_vm.meter.headline)+" ")]:_vm._e()],2),(_vm.meter.type)?_c('span',{staticClass:"header__title"},[_vm._v(" "+_vm._s(_vm.stringsShared.meterC.meter)+" "+_vm._s(_vm.displayMeterType(_vm.meter.type))+" ")]):_vm._e()])]),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"infos__meter"},[_c('div',{staticClass:"infos__meter__ids"},[_c('span',[_vm._v(_vm._s(_vm.stringsShared.meterC.ean)+" "+_vm._s(_vm.meter.ean))]),_vm._v(" "),_c('br'),(Array.isArray(_vm.meter.numCompteur))?[_c('div',{staticClass:"infos__meter__ids__list"},[_vm._v(" "+_vm._s(_vm.stringsShared.meterC.meters)+": "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatMeters(_vm.meter.numCompteur))}})])]:[_c('span',[_vm._v(_vm._s(_vm.stringsShared.meterC.meter)+": "),_c('br'),_vm._v(" "+_vm._s(_vm.meter.numCompteur))])]],2)]),_vm._t("cta",[(!_vm.meter.isSubmitted)?_c('g-btn',{attrs:{"btn":{ label: _vm.stringsShared.meterC.readingSubmit },"tag":"button"}}):_vm._e()])],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }