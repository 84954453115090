

































































































































































const { VUE_APP_ATRIAS_DOMAIN } = process.env

import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from '@vue/composition-api'
import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'
import Message from '@/components/ui/Message.vue'
import Meter from '@/components/Meter.vue'
import auth from '@/composables/auth'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'my-dashboard',
  components: {
    Message,
    'v-meter': Meter,
    CustomLink,
  },
  setup(_props) {
    const { refreshUser } = useActions('user', {
      refreshUser: 'refresh',
    })
    // const { SET_EAN_DETAILS } = useMutations('my', ['SET_EAN_DETAILS'])
    const message = ref(true)
    const mutations = {
      ...useMutations('my', ['START_LOADING', 'STOP_LOADING']),
    }

    onBeforeMount(() => {
      refreshUser()
    })

    onMounted(() => {
      mutations.STOP_LOADING()
    })
    // const storeData = ean => {
    //   SET_EAN_DETAILS({ ean })
    // }

    // watch(auth.currentUser, _ => {
    //   refreshUser()
    // })

    const myState = {
      ...useState('my', ['i18n']),
    }

    return {
      i18n: myState.i18n,
      message,
      ...useState('user', ['firstname', 'ppp']),
      ...useGetters('user', ['activeEans', 'localizedEans']),
      VUE_APP_ATRIAS_DOMAIN,
      isLogged: auth.isLogged,
      // storeData,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
