



















































































import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'v-meter',
  components: {},
  props: {
    meter: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const formatMeters = meters =>
      meters.map(item => ctx.root.$options?.filters?.trimzeros(item)).join(', ')

    const chrome = useState(['chrome'])
    const stringsShared = chrome.chrome.value.data.i18n

    const displayMeterType = (meterType: string) => {
      let html = ''
      if (meterType === 'elec') {
        html = stringsShared.meterC.elec
      } else if (meterType === 'gaz') {
        html = stringsShared.meterC.gaz
      } else if (meterType === 'propane') {
        html = stringsShared.meterC.propane
      }

      return html
    }

    const myState = {
      ...useState('my', ['i18n']),
    }

    return {
      formatMeters,
      displayMeterType,
      stringsShared,
      i18n: myState.i18n,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
